export default class MonthlyPurchaseChart {
  constructor(querySelector, buyingFrequencyChartSeries, buyingFrequencyChartCategories) {
    this.querySelector = querySelector;
    this.buyingFrequencyChartSeries = buyingFrequencyChartSeries; 
    this.buyingFrequencyChartCategories = buyingFrequencyChartCategories;
  }

  filterNullOnList(list) {
    return (list.every(elem => elem == 0) ? [] : list)
  }

  chartOptions() {
    return {
      chart: {
        type: "line",
        height: 350,
        toolbar: false
      },
      series: [
        {
          name: "valor total de compras",
          data: this.filterNullOnList(this.buyingFrequencyChartSeries)
        }
      ],
      colors: ['#E5B736', '#E9813F'],
      stroke: {
        width: [2, 0]
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [0]
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        markers: {
          radius: 0
        }
      },
      xaxis: {
        title: {
          text: 'Mostrando os últimos 24 meses'
        },
        categories: this.filterNullOnList(this.buyingFrequencyChartCategories)
      },
      yaxis: [{
        title: {
          text: 'Valor (R$)',
        },
        labels: {
          formatter: (value) => `R$ ${(value / 1000).toFixed(1)}k`
        }
      }],
      tooltip: {
        y: {
          formatter: function (value, series) {
            if (series.seriesIndex == 0) {
              return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
            }

            return parseInt(value);
          }
        }
      },
      noData: {
        text: "O cliente ainda não possui dados para exibição"
      }
    }
  }

  render() {
    let chart = new ApexCharts(document.querySelector(this.querySelector), this.chartOptions());
    return chart.render();
  }
}