require("@rails/ujs").start();
require("@rails/activestorage").start();
require('chart.js/dist/Chart.min.js');

import * as d3 from 'd3/dist/d3.js';
window.d3 = d3;

import ApexCharts from 'apexcharts';
window.ApexCharts = ApexCharts;

import MonthlyPurchaseChart from 'packs/charts/monthly_purchase_chart';
window.MonthlyPurchaseChart = MonthlyPurchaseChart;

import CreditLimitChart from 'packs/charts/credit_limit_chart';
window.CreditLimitChart = CreditLimitChart;

import PunctualityOfCustomerChart from 'packs/charts/punctuality_of_customer_chart';
window.PunctualityOfCustomerChart = PunctualityOfCustomerChart;

import InvoiceOpenedAndOverdueChart from 'packs/charts/invoice_opened_and_overdue_chart';
window.InvoiceOpenedAndOverdueChart = InvoiceOpenedAndOverdueChart;

import ProtestsChart from 'packs/charts/protests_chart';
window.ProtestsChart = ProtestsChart;

window.setTimeout(function() {
  $(".alert").hide();
}, 10000);

document.onclick = closeFlash;

function closeFlash() {
  $(".alert").hide();
}
