export default class ProtestsChart {
  constructor(querySelector, monthList, amountList, quantityList) {
    this.querySelector = querySelector
    this.monthList = monthList
    this.amountList = amountList
    this.quantityList = quantityList
  }

  filterNullOnList(list) {
    return (list.every(elem => elem == null) ? [] : list)
  }

  chartOptions() {
    return {
      chart: {
        type: "line",
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '20%',
          endingShape: 'rounded'
        },
      },
      series: [
        {
          name: "Valor",
          type: 'bar',
          data: this.filterNullOnList(this.amountList)
        },
        {
          name: 'Quantidade',
          type: 'line',
          data: this.filterNullOnList(this.quantityList)
        }
      ],
      colors: ['#2768C6', '#E5B736'],
      stroke: {
        curve: 'straight',
        dashArray: [0, 8, 5]
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        markers: {
          radius: 0
        }
      },
      xaxis: {
        title: {
          text: 'Período'
        },
        categories: this.monthList
      },
      yaxis: {
        title: {
          text: 'Valor (R$)'
        },
        labels: {
          formatter: (value) => `R$ ${(value / 1000).toFixed(1)}k`
        }
      },
      tooltip: {
        y: {
          formatter: function (value, _) { 
            if (_.series[0].includes(value)) {
              return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
            }
            return value;
          }
        }
      },
      noData: {
        text: 'Sem protestos',
        align: 'center',
        verticalAlign: 'middle'
      }
    }
  }

  render() {
    let chart = new ApexCharts(document.querySelector(this.querySelector), this.chartOptions());
    return chart.render();
  }
}