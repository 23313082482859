export default class InvoiceOpenedAndOverdueChart {
  constructor(querySelector, series) {
    this.querySelector = querySelector
    this.series = (series.every(elem => elem == 0) ? [] : series)
  }

  chartOption() {
    return {
      chart: {
        height: 450,
        type: 'donut',
        width: '100%'
      },
      stroke: {
        width: 0
      },
      series: this.series,
      labels: ['Em aberto', 'Vencidos acima de 90d', 'Vencidos em até 30d'],
      colors: ['#4F8A71', '#D67336', '#DDB851', '#215112'],
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          donut: {
            size: '76%',
          },
        },
      },
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'left',
        floating: false,
        markers: {
          width: 12,
          height: 12,
          strokeWidth: 0,
          radius: 3,
          offsetX: 0,
          offsetY: 1
        },
        offsetX: 0,
        offsetY: 0,
      },
      noData: {
        text: "O cliente ainda não possui dados para exibição"
      }
    }
  }

  render() {
    let chart = new ApexCharts(document.querySelector(this.querySelector), this.chartOption());
    return chart.render();
  }
}