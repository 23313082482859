export default class CreditLimitChart {
  constructor(querySelector, creditLimitChartData, creditLimitData, creditLimitChartCategories) {
    this.querySelector = querySelector
    this.creditLimitChartData = creditLimitChartData
    this.creditLimitData = creditLimitData
    this.creditLimitChartCategories = creditLimitChartCategories
  }

  filterNullOnList(list) {
    return (list.every(elem => elem == 0) ? [] : list)
  }

  chartOptions() {
    return {
      chart: {
        type: "line",
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '20%',
          endingShape: 'rounded'
        },
      },
      series: [
        {
          name: "Total crédito utilizado no mês",
          type: 'bar',
          data: this.filterNullOnList(this.creditLimitChartData)
        },
        {
          name: 'Limite atual',
          type: 'line',
          data: this.filterNullOnList(this.creditLimitData)
        }
      ],
      colors: ['#E5B736', '#E9813F'],
      stroke: {
        curve: 'straight',
        dashArray: [0, 8, 5]
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        markers: {
          radius: 0
        }
      },
      xaxis: {
        title: {
          text: 'Mostrando os últimos 24 meses'
        },
        categories: this.filterNullOnList(this.creditLimitChartCategories)
      },
      yaxis: {
        title: {
          text: 'Crédito utilizado'
        },
        labels: {
          formatter: (value) => `R$ ${(value / 1000).toFixed(1)}k`
        }
      },
      tooltip: {
        y: {
          formatter: function (value, series) {
            return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
          }
        }
      },
      noData: {
        text: "O cliente ainda não possui dados para exibição"
      }
    }
  }

  render() {
    let chart = new ApexCharts(document.querySelector(this.querySelector), this.chartOptions());
    return chart.render();
  }
}